import { Toaster } from "@atoms/toast/Toaster";
import { Layout } from "@layout/Layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { seoManager } from "@utils/seo-manager/seoManager";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { FC } from "react";
import { PageProps } from "../pages/types";

const queryClient = new QueryClient();

export const AppComponent: FC<AppProps<PageProps>> = ({
  Component,
  pageProps,
}: AppProps<PageProps>) => {
  const router = useRouter();
  const seo = seoManager({
    generalSettings: pageProps.generalSettings,
    pageSeo: pageProps?.pageSeo,
    pageDetails: pageProps?.pageDetails,
    generalInfo: {
      path: router.asPath,
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Layout
        menuItems={pageProps.menuItems}
        navCta={pageProps.navCta}
        isDarkNavbar={pageProps.isDarkNavbar}
        footerContent={pageProps.footerContent}
        seoData={seo}
      >
        <Component {...pageProps} />
      </Layout>
      <Toaster />
    </QueryClientProvider>
  );
};
