import Icon from "@atoms/icon/Icon";
import Link from "next/link";
import { FC } from "react";
import { cn } from "utils/tailwind";
import styles from "./Logo.module.css";
import { LogoProps } from "./Logo.types";

export const Logo: FC<LogoProps> = ({
  isBackground,
  isOpen,
  isDetailedNavOpen,
  className,
}) => {
  const baseClasses = "relative cursor-pointer";
  const logoVariant = isBackground ? styles.logoDark : styles.logoLight;
  const openLogo = isOpen || isDetailedNavOpen ? styles.logoOpen : " ";

  return (
    <Link className={cn(baseClasses, className)} href="/">
      <Icon
        name="VirtusLogoWithText"
        className={cn("h-8 w-[82px] fill-blue-light", logoVariant, openLogo)}
      />
    </Link>
  );
};
