import { Image } from "@atoms/image/Image";
import { ApiImage } from "@page-components/types";
import { cn, tw } from "@utils/tailwind";
import { AnimatePresence, motion, wrap } from "framer-motion";
import { FC, useEffect, useState } from "react";

const variants = {
  enter: { x: "var(--viewport-width-with-gap)", opacity: 0 },
  center: { x: 0, opacity: 1 },
  exit: { x: "var(--neg-viewport-width-with-gap)", opacity: 0 },
};

const motionDivStyles = tw`absolute
  size-full
  [--neg-viewport-width-with-gap:calc(-16px-var(--viewport-width))]
  [--viewport-width-with-gap:calc(16px+var(--viewport-width))]`;

export const ImagesSlideshow: FC<{
  logos: ApiImage[];
  disableAnimation?: boolean;
  imageClassName?: string;
}> = ({ logos, imageClassName, disableAnimation }) => {
  const [slideInd, setSlideInd] = useState(0);
  const logoInd = wrap(0, logos.length, slideInd);
  const nextLogoInd = wrap(0, logos.length, slideInd + 1);

  useEffect(() => {
    if (disableAnimation) return;
    const id = setInterval(() => setSlideInd((x) => x + 1), 2800);
    return () => clearInterval(id);
  }, [disableAnimation]);

  return (
    <AnimatePresence initial={false}>
      <motion.div
        key={slideInd}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { ease: "easeInOut", duration: 1 },
          opacity: { duration: 0.4 },
        }}
        className={motionDivStyles}
      >
        <Image
          backgroundImage
          imageData={logos[logoInd]}
          className={cn("object-contain object-left", imageClassName)}
        />
        {/* Prefetch next hidden image */}
        <Image imageData={logos[nextLogoInd]} className="absolute size-0" />
      </motion.div>
    </AnimatePresence>
  );
};
