import Icon from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormPopover } from "@layout/footer/form/FormPopover";
import { UnderInputText } from "@layout/footer/form/UnderInputText";
import { DesktopSubmitButton } from "@layout/footer/form/submit-buttons/DesktopSubmitButton";
import { MobileSubmitButton } from "@layout/footer/form/submit-buttons/MobileSubmitButton";
import { subscribeNewsletter } from "@utils/api/handlers/newsletter";
import { cn, tw } from "@utils/tailwind";
import { isEmpty } from "lodash";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Newsletter } from "../Footer.types";
import { footerFormSchema, FooterFormSchema } from "./FooterForm.helpers";

const baseInputStyles = tw`w-56
  rounded-full
  bg-content-primary-on-dark
  px-4
  py-3
  text-black
  outline-none
  ring-grey-scale-light-gray
  ring-offset-2
  ring-offset-black
  placeholder:text-grey-mid
  focus:ring-1
  md:w-64`;

export const FooterForm: FC<Newsletter> = ({
  title,
  subtitle,
  inputPlaceholder,
  submit: submitLabel,
  linkLabel,
  tooltip,
}) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm<FooterFormSchema>({
    resolver: zodResolver(footerFormSchema),
  });

  const errorMsg =
    errors.root?.serverError?.message || errors.email?.message || "";

  const successMsg = isSubmitSuccessful ? "Great! We'll stay in touch." : "";

  const inputStyles = cn(
    baseInputStyles,
    !isEmpty(errors) && "ring-1 ring-orange-saturated",
    isSubmitSuccessful && "ring-1 ring-green-accent-one"
  );

  const submit: SubmitHandler<FooterFormSchema> = async (values) => {
    const result = await subscribeNewsletter({ email: values.email });

    if (!result.success) {
      const suffix = result.errorMessage
        ? result.errorMessage
        : "try again later";
      setError("root.serverError", {
        type: "server",
        message: `Oops, something is glitched: ${suffix}`,
      });
    }
  };

  return (
    <form
      noValidate
      className="xl:mr-8 xl:w-[28.5rem]"
      onSubmit={handleSubmit(submit)}
    >
      <Typography
        tag="p"
        variant="h5"
        className="mb-1.5 font-termina font-medium"
      >
        {title}
      </Typography>
      <Typography tag="p" variant="sm" className="mb-4 md:mb-6 xl:mb-7">
        {subtitle}
      </Typography>
      <div className="mb-2 flex items-center md:mb-1.5">
        <input
          type="email"
          className={inputStyles}
          placeholder={inputPlaceholder}
          {...register("email")}
        />
        <MobileSubmitButton loading={isSubmitting} show={!isSubmitSuccessful} />
        <DesktopSubmitButton
          loading={isSubmitting}
          label={submitLabel}
          show={!isSubmitSuccessful}
        />
        <Icon
          name="Thick"
          className={cn(
            "ml-9 size-4 text-green-accent-two xl:ml-12",
            isSubmitSuccessful ? "block" : "hidden"
          )}
        />
      </div>
      <UnderInputText successMsg={successMsg} errorMsg={errorMsg} />
      <FormPopover label={linkLabel} content={tooltip} />
    </form>
  );
};
