import { ReactNode } from "react";

export enum LinkVariants {
  FOOTER = "footer",
  MENU = "menu",
  MENU_WITH_ICON = "menuWithIcon",
  MENU_LIGHT = "menuLight",
  MENU_LIGHT_WITH_ICON = "menuLightWithIcon",
  SUBMENU = "submenu",
}

export type LinkProps = {
  href?: string;
  children: ReactNode;
  noStyle?: boolean;
  noFollow?: boolean;
  newWindow?: boolean;
  variant?: LinkVariants;
  active?: boolean;
  iconContainerClassName?: string;
  childrenContainerClassName?: string;
  icon?: string;
};
