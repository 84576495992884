import { NextSeo } from "next-seo";
import { FC, ReactNode } from "react";
import { Footer } from "./footer/Footer";
import { FooterProps } from "./footer/Footer.types";
import { Navbar } from "./navbar/Navbar";
import { NavElement } from "./navbar/Navbar.constants";
import { NavCta } from "./navbar/navbar-dropdown/image-with-text/ImageWithText.types";

type LayoutProps = {
  children: ReactNode;
  menuItems: NavElement[];
  navCta: NavCta;
  isDarkNavbar?: boolean;
  footerContent: FooterProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  seoData: any;
};

export const Layout: FC<LayoutProps> = ({
  children,
  menuItems,
  navCta,
  isDarkNavbar,
  footerContent,
  seoData,
}) => (
  <>
    <NextSeo
      title={seoData.title}
      description={seoData.description}
      canonical={seoData.linkCanonical}
      openGraph={{
        locale: seoData.locale,
        title: seoData.ogTitle,
        description: seoData.ogDescription,
        url: seoData.ogUrl,
        site_name: seoData.ogSiteName,
        type: seoData.ogType,
        article: {
          authors: [seoData.articlePublisher],
          modifiedTime: seoData.articleModifiedTime,
        },
        images: [
          {
            url: seoData.ogImage,
            width: seoData.ogImageWidth,
            height: seoData.ogImageHeight,
            type: seoData.ogImageType,
          },
        ],
      }}
      twitter={{
        ...(seoData.twitterCard && { cardType: "summary_large_image" }),
        site: seoData.twitterSite,
      }}
      noindex={seoData.metaRobots === "noindex"}
      nofollow={seoData.metaRobots === "noindex"}
      robotsProps={{
        ...seoData.metaRobots,
        ...(seoData.metaRobots !== "noindex" && {
          maxImagePreview: "large",
          maxSnippet: -1,
          maxVideoPreview: -1,
        }),
      }}
      additionalMetaTags={[
        { name: "keywords", content: seoData.keywords },
        {
          name: "google-site-verification",
          content: seoData.googleSiteVerification,
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
      ]}
      additionalLinkTags={[
        ...(seoData.linkIcon ? [{ rel: "icon", href: seoData.linkIcon }] : []),
        ...(seoData.linkNextPage
          ? [{ rel: "next", href: seoData.linkNextPage }]
          : []),
        ...(seoData.linkPrevPage
          ? [{ rel: "prev", href: seoData.linkPrevPage }]
          : []),
      ]}
    />
    <Navbar menuItems={menuItems} navCta={navCta} isDarkNavbar={isDarkNavbar} />
    {children}
    <Footer {...footerContent} />
  </>
);
