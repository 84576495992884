import Icon from "@atoms/icon/Icon";
import Typography from "@atoms/typography/Typography";
import { tw } from "@utils/tailwind";
import { FC } from "react";
import { FooterProps } from "../Footer.types";
import { buildSocialIconTitle } from "./FooterLinks.helpers";

const linksStyles = tw`mb-9
  grid
  grid-cols-[repeat(auto-fit,minmax(90px,1fr))]
  gap-x-6
  gap-y-4
  empty:hidden
  sm:grid-cols-[repeat(auto-fit,minmax(105px,1fr))]
  md:mb-14
  md:gap-x-14
  md:gap-y-5
  lg:grid-cols-[repeat(5,auto)]
  lg:justify-between
  xl:mb-16
  2xl:mb-11
  2xl:grid-cols-2`;

export const FooterLinks: FC<Pick<FooterProps, "links" | "socialMedia">> = ({
  links,
  socialMedia,
}) => (
  <nav className="xl:col-span-2 2xl:col-span-1">
    <ul className={linksStyles}>
      {links?.map(({ message, url }) => (
        <Typography tag="li" variant="sm" key={message}>
          <a
            href={url}
            className="link text-inherit no-underline outline-none focus:underline"
          >
            {message}
          </a>
        </Typography>
      ))}
    </ul>
    <ul className="flex gap-10 empty:hidden">
      {socialMedia?.items?.map(({ icon, url }) => (
        <li key={url} className="flex-none">
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            title={buildSocialIconTitle(url)}
          >
            <Icon
              className="size-5 transition-colors duration-100 ease-in hover:fill-green-accent-one focus:fill-green-accent-one"
              name={icon}
            />
          </a>
        </li>
      ))}
    </ul>
  </nav>
);
