import { ApiImage } from "@page-components/types";
import { ResponsiveImages } from "@utils/images/responsive-images";
import { breakpointValues } from "@utils/theming/breakpoints";

export type ImageProps = React.ComponentPropsWithoutRef<"img"> & {
  imageData: ApiImage | string | ResponsiveImages | undefined;
  backgroundImage?: boolean;
  maxKnownWidth?: number;
  unoptimized?: boolean;
  pictureClassName?: string;
  allowLightBox?: boolean;
  disableAutomaticAspectRatio?: boolean;
};

export type PictureArrayProps = {
  url: string;
  condition: string;
}[];

export const mockedImageData: ApiImage = {
  id: 23,
  attributes: {
    name: "capability-desktop.png",
    alternativeText: null,
    caption: null,
    width: 1920,
    height: 629,
    mime: "image/png",
    url: "https://cdn.virtuslab.com/Header_7b4d38032e.png",
  },
};

export const imageBreakpoints = {
  XXL: `(min-width: ${breakpointValues.xxl}px)`,
  XL: `(min-width: ${breakpointValues.xl}px)`,
  LG: `(min-width: ${breakpointValues.lg}px)`,
  MD: `(min-width: ${breakpointValues.md}px)`,
  SM: `(min-width: ${breakpointValues.sm}px)`,
};
