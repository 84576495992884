import { cn } from "@utils/tailwind";
import { FC } from "react";
import { ImageProps, PictureArrayProps } from "./Image.constants";
import {
  generateSrcSet,
  setAltImage,
  setBackupImageUrl,
  setWidthAndHeight,
} from "./Image.helpers";

export const Image: FC<ImageProps> = ({
  backgroundImage,
  imageData,
  allowLightBox = false,
  maxKnownWidth,
  unoptimized = false,
  pictureClassName = "",
  disableAutomaticAspectRatio = false,
  ...rest
}) => {
  let pictureProps: PictureArrayProps = [];
  const [width, height] = setWidthAndHeight(imageData);

  if (typeof imageData !== "string" && imageData) {
    pictureProps = generateSrcSet(imageData, maxKnownWidth, unoptimized);
  }
  const backgroundStyles = backgroundImage
    ? "absolute left-0 top-0 size-full object-cover"
    : "w-full h-auto";
  return (
    imageData && (
      <picture
        {...(allowLightBox ? { "data-lightbox": "true" } : {})}
        className={pictureClassName}
      >
        {pictureProps.map((source, index) => (
          <source
            key={`${index}-keyed`}
            media={source.condition}
            srcSet={source.url}
          />
        ))}
        <img
          src={setBackupImageUrl(imageData, unoptimized, maxKnownWidth)}
          alt={setAltImage(imageData)}
          aria-label={setAltImage(imageData)}
          style={
            !disableAutomaticAspectRatio && !!width && !!height
              ? { aspectRatio: `${width}/${height}` }
              : {}
          }
          {...rest}
          className={cn(backgroundStyles, rest.className)}
        />
      </picture>
    )
  );
};
