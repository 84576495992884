import { Button } from "@atoms/button/Button";
import Icon from "@atoms/icon/Icon";
import { Link } from "@atoms/link/Link";
import { LinkVariants } from "@atoms/link/Link.constants";
import React, { FC, useEffect, useMemo, useState } from "react";
import { cn } from "utils/tailwind";
import { NavElement } from "../navbar/Navbar.constants";
import { SideMenuProps } from "./SideMenu.types";
import { TabletNav } from "./tabletNav/TabletNav";

const ContactButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    intent="secondaryV2"
    href="/contact"
    icon="ArrowRight"
    target="_self"
    onClick={onClick}
    className="mx-auto my-0 w-[calc(100%-60px)] md:w-[calc(100%-100px)] "
    asLink
  >
    Contact
  </Button>
);

export const SideMenu: FC<SideMenuProps> = ({
  navElements,
  isOpen,
  setIsOpen,
}) => {
  const [selectedNavElement, setSelectedNavElement] = useState<string>();

  useEffect(() => {
    if (!isOpen) setSelectedNavElement(undefined);
  }, [isOpen]);

  const onLinkClick = (
    navElement: NavElement,
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    if (navElement?.type === "WRAPPER") {
      event.preventDefault();
      setSelectedNavElement(navElement.id);
    } else {
      setIsOpen(false);
    }
  };

  const generateNavElements = () =>
    navElements.map((navElement) => (
      //TODO add a prop to show arrow icon for links like careers
      <Link
        key={navElement.title}
        href={navElement.link}
        onClick={(event) => onLinkClick(navElement, event)}
        variant={LinkVariants.MENU_WITH_ICON}
        icon={navElement.title.includes("Careers") ? "outside" : undefined}
        className="ml-auto w-full justify-end !pr-12"
      >
        {navElement.title}
      </Link>
    ));

  const generateDetailedNavElements = () => {
    const selectedNavElementObj = navElements.find(
      (el) => el.id === selectedNavElement
    );
    if (!selectedNavElementObj) return null;
    return selectedNavElementObj.children.map((subElement) => (
      <div key={subElement.sectionTitle} className="flex flex-col items-start">
        <div className="t-headline-h6 mb-5 mt-2 text-xs uppercase text-grey-scale-off-black">
          {subElement.sectionTitle}
        </div>
        {subElement.children.map((el) => (
          <Link
            className="mb-2.5"
            href={el.link}
            variant={LinkVariants.SUBMENU}
            key={el.label}
            onClick={() => setIsOpen(false)}
          >
            {el.label}
          </Link>
        ))}
      </div>
    ));
  };

  const detailedNavTitle = useMemo(() => {
    const selectedNavElementObj = navElements.find(
      (el) => el.id === selectedNavElement
    );
    if (!selectedNavElementObj) return null;
    return selectedNavElementObj.title;
  }, [navElements, selectedNavElement]);

  const detailedNavOpenClass = selectedNavElement
    ? "bg-blue-light"
    : "bg-grey-scale-off-black";

  return (
    <>
      <TabletNav isOpen={isOpen}>
        <div
          className={cn(
            "fixed right-0 top-0 z-40 h-[85px] w-full transition-all duration-500 ease-in-out"
          )}
        />
        <div className="my-auto flex flex-col items-end  overflow-y-auto overflow-x-clip  landscape:mt-20 landscape:h-[500px]">
          <div className="t-headline-h6 mb-5 mr-14 text-grey-scale-gray">
            MENU
          </div>
          <div className="w-full ">{generateNavElements()}</div>
        </div>
        <ContactButton onClick={() => setIsOpen(false)} />
      </TabletNav>
      <TabletNav
        className={cn(
          "w-full min-w-0  transition-all duration-500 ease-in-out md:fixed md:flex md:h-screen md:w-1/2 md:min-w-[400px]",
          detailedNavOpenClass
        )}
        isOpen={!!selectedNavElement}
      >
        <div className="mt-28 flex h-full  flex-col overflow-scroll overflow-y-auto overflow-x-clip px-7 pb-7 sm:px-12 sm:pb-12">
          <div className="mb-8 mt-auto flex flex-col sm:mb-12">
            <Button
              intent="secondaryV2"
              size="small"
              onClick={() => setSelectedNavElement(undefined)}
              className="mb-6 w-fit p-1 px-5"
            >
              <span className="inline-flex flex-nowrap items-center gap-x-2">
                <Icon name="ArrowLeftShort" height={10} width={10} />
                <span className="pt-0.5">Back</span>
              </span>
            </Button>
            <div className="t-headline-h6 mb-3 text-base uppercase text-grey-scale-text">
              {detailedNavTitle}
            </div>
            {generateDetailedNavElements()}
          </div>
        </div>
        <ContactButton onClick={() => setIsOpen(false)} />
      </TabletNav>
    </>
  );
};
