import { withoutTrailingSlash } from "@utils/urls/url-utils";
import { useRouter } from "next/router";
import { FC, useEffect } from "react";
import { cn } from "utils/tailwind";
import { NavbarDropdownProps } from "./NavbarDropdown.types";
import { DropdownLink } from "./dropdown-link/DropdownLink";
import { ImageWithText } from "./image-with-text/ImageWithText";

export const NavbarDropdown: FC<NavbarDropdownProps> = ({
  children,
  elements,
  onMouseEnter,
  onMouseLeave,
  navCta,
  onLinkClick,
  isHoverable,
  setIsHoverable,
}) => {
  const { asPath } = useRouter();

  useEffect(() => {
    if (!isHoverable) {
      setIsHoverable(true);
    }
  }, [isHoverable, setIsHoverable]);

  return (
    <div
      className={cn(
        "pointer-events-auto flex h-full",
        isHoverable && "group/navbar"
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      {elements.length ? (
        <div className="absolute left-0 top-20 flex h-0 w-full flex-row overflow-hidden bg-gray-100 group-hover/navbar:h-auto group-hover/navbar:min-h-60">
          <div className="flex w-2/3 flex-row gap-x-10 py-8 pl-36 pr-24 xl:pl-56">
            {elements.map((el) => (
              <div
                key={el.sectionTitle}
                className="flex w-1/4 flex-col gap-y-1.5 xl:gap-y-2.5 2xl:gap-y-5"
              >
                <div className="mb-1 font-sans text-xs font-semibold uppercase text-grey-scale-gray xl:mb-2 2xl:mb-3">
                  {el.sectionTitle}
                </div>
                {el.children.map((el) => (
                  <DropdownLink
                    active={
                      withoutTrailingSlash(el.link) ==
                      withoutTrailingSlash(asPath)
                    }
                    key={el.label}
                    href={el.link}
                    onClick={onLinkClick}
                  >
                    {el.label}
                  </DropdownLink>
                ))}
              </div>
            ))}
          </div>
          {navCta && <ImageWithText navCta={navCta} />}
        </div>
      ) : null}
    </div>
  );
};
