import { Button } from "@atoms/button/Button";
import { Link } from "@atoms/link/Link";
import { LinkVariants } from "@atoms/link/Link.constants";
import { IconsNames } from "@icons/allIcons";
import { cn } from "@utils/tailwind";
import { breakpointValues } from "@utils/theming/breakpoints";
import { useResolution } from "@utils/use-resolution/useResolution";
import { useRouter } from "next/router";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { SideMenu } from "../side-menu/SideMenu";
import { hiddenNavbarPages } from "./Navbar.constants";
import { NavbarProps } from "./Navbar.types";
import { Logo } from "./logo/Logo";
import { NavbarDropdown } from "./navbar-dropdown/NavbarDropdown";
import { ToggleMenuButton } from "./toggle-menu-button/ToggleMenuButton";

export const Navbar: FC<NavbarProps> = ({
  menuItems = [],
  navCta,
  isDarkNavbar,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDetailedNavOpen, setIsDetailedNavOpen] = useState(false);
  const [isDropdownHoverable, setIsDropdownHoverable] = useState(true);
  const [isBackground, setIsBackground] = useState(false);
  const [isNavbarLinkHovered, setIsNavbarLinkHovered] = useState(false);
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const { width } = useResolution();
  const router = useRouter();
  const showNavbar = !hiddenNavbarPages.includes(router.pathname);

  const prevScrollPosRef = useRef(0);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    setIsBackground(currentScrollPos > 10);
    setIsScrolledDown(currentScrollPos > 10);
    prevScrollPosRef.current = currentScrollPos;
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isOpen]);

  useEffect(() => {
    if (width) {
      setIsOpen(false);
      setIsDetailedNavOpen(false);
      document.body.style.overflowY = "auto";
    }
  }, [width]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    setIsDetailedNavOpen((prev) => (prev ? false : prev));
  };

  const isExternalLink = (link: string): boolean => {
    if (link) {
      return !(
        link.includes("www.virtuslab.com") ||
        link.includes("/virtuslab.com") ||
        link.charAt(0) === "/"
      );
    }
    return false;
  };

  const isLinkScrolledDownStyles = isScrolledDown
    ? "after:top-[78px]"
    : "after:top-[66px]";

  const generateNavElements = () =>
    menuItems.map((el) => (
      <NavbarDropdown
        key={el.title}
        elements={el.children}
        onMouseEnter={() =>
          el.children.length > 0 && setIsNavbarLinkHovered(true)
        }
        onMouseLeave={() =>
          el.children.length > 0 && setIsNavbarLinkHovered(false)
        }
        navCta={navCta}
        onLinkClick={() => {
          setIsDropdownHoverable(false);
        }}
        isHoverable={isDropdownHoverable}
        setIsHoverable={setIsDropdownHoverable}
      >
        <Link
          href={el.link}
          onClick={(e) => el.type === "WRAPPER" && e.preventDefault()}
          variant={
            isBackground || isNavbarLinkHovered || !!isDarkNavbar
              ? LinkVariants.MENU_WITH_ICON
              : LinkVariants.MENU_LIGHT_WITH_ICON
          }
          newWindow={el.link ? isExternalLink(el.link) : false}
          className={cn(
            "mr-7",
            el.children.length ? "after:block" : "border-none after:hidden",
            isLinkScrolledDownStyles
          )}
          iconContainerClassName={
            "visible"
            //   cn(
            //   el.children.length ? "visible" : "invisible"
            // )
          }
          icon={el.link && isExternalLink(el.link) ? "outside" : undefined}
        >
          {el.title}
        </Link>
      </NavbarDropdown>
    ));

  const isButtonForLightBackground =
    isBackground ||
    isNavbarLinkHovered ||
    ((isDetailedNavOpen || isOpen) && (width || 0) < breakpointValues.lg) ||
    !!isDarkNavbar;

  const containerStyles = showNavbar ? "block" : "hidden";

  const navbarContainerWithBackgroundStyles =
    isBackground || isNavbarLinkHovered
      ? "bg-[rgba(249,249,249,0.92)]"
      : "bg-transparent";
  const navbarContainerScrolledDownStyles = isScrolledDown ? "pt-0" : "pt-3";

  return (
    <>
      <div
        className={cn("fixed left-0 top-0 z-50 h-0 w-full", containerStyles)}
      >
        <div className={navbarContainerWithBackgroundStyles}>
          <div
            className={cn(
              "container-flexible flex h-[var(--navbar-height)] items-center",
              navbarContainerScrolledDownStyles
            )}
          >
            <div tabIndex={0} className="mr-20 2xl:mr-40">
              <Logo
                isBackground={
                  isBackground || isNavbarLinkHovered || !!isDarkNavbar
                }
                isOpen={isOpen}
                isDetailedNavOpen={isDetailedNavOpen}
                className="mb-2 block"
              />
            </div>
            <nav className="hidden h-full items-end lg:flex">
              {generateNavElements()}
            </nav>
            <Button
              id="menu-cta"
              className="ml-auto hidden [@media(min-width:1136px)]:flex"
              intent="secondaryV2"
              href="/contact"
              icon={"ArrowRight" as IconsNames}
              target="_self"
              size="small"
              background={!isButtonForLightBackground ? "dark" : "light"}
              asLink
            >
              Contact
            </Button>
            <ToggleMenuButton
              onClick={toggleNavbar}
              isClicked={isDetailedNavOpen || isOpen}
              isForLightBackground={isButtonForLightBackground}
            />
          </div>
        </div>
      </div>
      <SideMenu navElements={menuItems} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
