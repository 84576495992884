import { tw } from "@utils/tailwind";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";

const rootStyles = tw`fixed
  bottom-7
  right-7
  z-[999]
  box-border
  flex
  flex-col
  gap-2
  rounded-md
  border
  border-blue-saturated
  bg-blue-light
  px-3
  py-2
  font-sans
  text-sm
  text-grey-scale-off-black`;

export const PreviewBanner: FC<{ previewMode: boolean }> = ({
  previewMode,
}) => {
  const router = useRouter();
  const path = router.asPath;

  if (!previewMode) return null;
  return (
    <div className={rootStyles}>
      <span>
        You are currently viewing in <strong>Preview Mode</strong>
      </span>
      <Link
        prefetch={false}
        className="underline underline-offset-2"
        href={`/api/exit-preview?path=${path}`}
      >
        End preview
      </Link>
    </div>
  );
};
