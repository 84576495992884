import { env } from "@/env";
import { getImageUrl } from "../strapi/images";
import { PageType, SeoManagerProps } from "./seoManager.constants";
import { mapLocale } from "./seoManager.helpers";

export const seoManager = ({
  generalSettings,
  pageSeo,
  pageDetails,
  generalInfo,
}: SeoManagerProps) => {
  const facebookMetaData = pageSeo?.metaSocial?.find(
    (social) => social.socialNetwork === "Facebook"
  );

  const twitterMetaData = pageSeo?.metaSocial?.find(
    (social) => social.socialNetwork === "Twitter"
  );

  const metaRobots = pageDetails?.paginatedPage
    ? [{ metaRobotsOption: "noindex" }]
    : pageSeo?.metaRobots;

  return {
    metaRobots: metaRobots
      ?.map((metaRobot) => metaRobot.metaRobotsOption)
      .join(", "),
    title: pageSeo?.metaTitle ? pageSeo?.metaTitle : pageDetails?.title,
    description: facebookMetaData?.description
      ? facebookMetaData?.description
      : pageSeo?.metaDescription
        ? pageSeo?.metaDescription
        : pageDetails?.excerpt,
    linkCanonical: pageDetails?.paginatedPage
      ? `${env.NEXT_PUBLIC_DOMAIN}/${pageDetails?.canonicalPageUrl}`
      : pageSeo?.canonicalURL
        ? pageSeo?.canonicalURL
        : `${env.NEXT_PUBLIC_DOMAIN}${generalInfo?.path || ""}`,
    locale: mapLocale(pageDetails?.locale),
    ogTitle: facebookMetaData?.title
      ? facebookMetaData?.title
      : pageSeo?.metaTitle
        ? pageSeo?.metaTitle
        : pageDetails?.title,
    ogDescription: facebookMetaData?.description
      ? facebookMetaData?.description
      : pageSeo?.metaDescription
        ? pageSeo?.metaDescription
        : pageDetails?.excerpt,
    ogUrl: `${env.NEXT_PUBLIC_DOMAIN}${generalInfo?.path || ""}`,
    ogSiteName: generalSettings?.page_name,
    articlePublisher: generalSettings?.seo_facebook_url,
    articleModifiedTime: pageDetails?.updatedAt,
    ogImage: getImageUrl(
      facebookMetaData?.image?.data
        ? facebookMetaData?.image?.data
        : pageSeo?.metaImage?.data
    ),
    ogType: pageDetails?.pageType ?? PageType.WEB_PAGE,
    ogImageWidth: pageSeo?.metaImage?.data?.attributes?.width,
    ogImageHeight: pageSeo?.metaImage?.data?.attributes?.height,
    ogImageType: pageSeo?.metaImage?.data?.attributes?.mime,
    twitterCard: !!twitterMetaData,
    twitterTitle: twitterMetaData?.title,
    twitterDescription: twitterMetaData?.description,
    twitterImage: getImageUrl(twitterMetaData?.image?.data),
    twitterSite: generalSettings?.seo_twitter_user,
    googleSiteVerification: generalSettings?.google_site_verification,
    keywords: pageSeo?.keywords,
    linkIcon: getImageUrl(generalSettings?.favicon?.data),
    linkNextPage:
      pageDetails?.nextPageLink &&
      `${env.NEXT_PUBLIC_DOMAIN}/${pageDetails?.nextPageLink}`,
    linkPrevPage:
      pageDetails?.prevPageLink &&
      `${env.NEXT_PUBLIC_DOMAIN}/${pageDetails?.prevPageLink}`,
  };
};
