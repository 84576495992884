import { cn } from "@utils/tailwind";
import { isNil, mapValues } from "lodash";
import NextLink from "next/link";
import React, { ComponentProps, FC, PropsWithChildren } from "react";
import { LinkProps, LinkVariants } from "./Link.constants";
import {
  footerChildrenContainerStyles,
  iconContainerStyles,
  linkStyles,
} from "./Link.styles";
import { LinkIcon } from "./link-icon/linkIcon";

const LinkOrDiv: FC<
  PropsWithChildren<{ showDiv?: boolean } & ComponentProps<typeof NextLink>>
> = ({ showDiv, children, href, ...rest }) =>
  showDiv ? (
    <div
      data-name="No Link"
      tabIndex={rest.tabIndex}
      className={rest.className}
    >
      {children}
    </div>
  ) : (
    <NextLink data-name="Link" href={href} {...rest}>
      {children}
    </NextLink>
  );

export const Link: FC<
  LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({
  icon,
  className,
  iconContainerClassName,
  childrenContainerClassName,
  children,
  href,
  noStyle,
  noFollow,
  newWindow,
  variant = LinkVariants.FOOTER,
  active = false,
  ...rest
}) => {
  const isRightSideIconVisible =
    variant === LinkVariants.MENU_WITH_ICON ||
    variant === LinkVariants.MENU ||
    variant === LinkVariants.MENU_LIGHT ||
    variant === LinkVariants.MENU_LIGHT_WITH_ICON;

  const isLinkArr = [
    LinkVariants.SUBMENU,
    LinkVariants.FOOTER,
    LinkVariants.MENU_WITH_ICON,
    LinkVariants.MENU_LIGHT_WITH_ICON,
  ];

  const noLink = !isLinkArr.includes(variant) || isNil(href);

  const styles = mapValues(
    {
      root: linkStyles({ active, variant }),
      lastIconContainer: iconContainerStyles({ variant, active }),
      childrenSpan:
        variant === LinkVariants.FOOTER && footerChildrenContainerStyles,
    },
    (it) => (noStyle ? "" : it)
  );

  return (
    <LinkOrDiv
      className={cn(styles.root, className)}
      showDiv={noLink}
      href={href || ""}
      prefetch={false}
      {...rest}
      rel={noFollow ? "nofollow noreferrer" : undefined}
      target={newWindow ? "_blank" : "_self"}
      tabIndex={0}
    >
      <div
        data-name="Children container"
        className={childrenContainerClassName}
      >
        <span data-name="Children span" className={cn(styles.childrenSpan)}>
          {children}
        </span>
      </div>

      {isRightSideIconVisible && (
        <div
          data-name="Last icon container"
          className={cn(styles.lastIconContainer, iconContainerClassName)}
        >
          <LinkIcon linkVariant={icon ? (icon as LinkVariants) : variant} />
        </div>
      )}
    </LinkOrDiv>
  );
};
