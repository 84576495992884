import { Image } from "@atoms/image/Image";
import { ImagesSlideshow } from "@molecules/images-slideshow/ImagesSlideshow";
import { ApiImage } from "@page-components/types";
import { tw } from "@utils/tailwind";
import { FC } from "react";

const staticIconsStyles = tw`grid
  h-full
  w-[9.25rem]
  grid-cols-[0.6fr,0.4fr]
  items-center
  gap-5
  pr-4
  md:w-[17.75rem]
  md:gap-10
  md:py-4
  md:pr-10
  xl:w-[17.25rem]
  xl:py-10
  xl:pr-8
  2xl:w-40
  2xl:grid-cols-1
  2xl:grid-rows-[0.33fr,0.67fr]
  2xl:py-0
  2xl:pr-10`;

const dynamicIconsStyles = tw`relative
  h-full
  w-[--viewport-width]
  overflow-hidden
  [--viewport-width:142px]
  md:[--viewport-width:222px]`;

export const FooterLogos: FC<{
  disableAnimation?: boolean;
  staticLogos?: ApiImage[];
  animatedLogos?: ApiImage[];
}> = ({ staticLogos, animatedLogos, disableAnimation }) => (
  <div className="flex h-11 items-center md:h-28 xl:h-[10.5rem] xl:justify-self-end 2xl:mr-1 2xl:h-44 2xl:w-[26.25rem] 2xl:justify-self-auto">
    <div className={staticIconsStyles}>
      {staticLogos?.map((image, ind) => (
        <div key={ind} className="relative size-full md:py-4 2xl:py-0">
          <Image backgroundImage imageData={image} className="object-contain" />
        </div>
      ))}
    </div>
    <div className="mr-4 h-full w-px bg-grey-scale-gray/70 md:mr-10 xl:mr-8 2xl:mr-10" />
    <div className={dynamicIconsStyles}>
      <ImagesSlideshow
        disableAnimation={disableAnimation}
        logos={animatedLogos ?? []}
        imageClassName="md:py-4 xl:py-11"
      />
    </div>
  </div>
);
