import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { FooterForm } from "@layout/footer/form/FooterForm";
import { FooterLinks } from "@layout/footer/links/FooterLinks";
import { FooterLogos } from "@layout/footer/logos/FooterLogos";
import decorativeImg from "public/images/footer/footer_bottom_waves.svg";
import { FC } from "react";
import { FooterProps } from "./Footer.types";

const currentYear = new Date().getFullYear();

export const Footer: FC<FooterProps> = ({
  links,
  socialMedia,
  newsletter,
  disableAnimation,
  staticLogos,
  animatedLogos,
}) => (
  <div className="relative bg-content-primary-on-light">
    <div
      className="absolute bottom-0 left-0 h-48 w-full bg-cover bg-top bg-no-repeat"
      style={{ backgroundImage: `url(${decorativeImg.src})` }}
    />
    <footer className="container-flexible pb-44 md:pb-48 lg:pb-52 xl:pb-40 2xl:pb-40">
      <ContainerSpacing
        asDiv
        withBackground
        isTransparentBackground
        className="mb-4 grid items-center gap-9 !pb-0 text-grey-scale-light-gray md:mb-6 md:gap-14 lg:gap-16 xl:mb-5 xl:grid-cols-2 xl:justify-between xl:gap-0 xl:gap-y-20 2xl:grid-cols-[repeat(3,auto)]"
      >
        <FooterForm {...newsletter} />
        <FooterLogos
          disableAnimation={disableAnimation}
          staticLogos={staticLogos}
          animatedLogos={animatedLogos}
        />
        <FooterLinks links={links} socialMedia={socialMedia} />
      </ContainerSpacing>
      <Typography tag="p" variant="xs" className="text-blue-light/50">
        ©{currentYear} VirtusLab
      </Typography>
    </footer>
  </div>
);
