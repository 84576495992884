import {
  SettingsSectionsSiteSettingsComponent,
  SharedSeoComponent,
} from "@api/contract";

export enum PageType {
  ARTICLE = "Article",
  WEB_PAGE = "WebPage",
  CONTACT = "Contact",
}

export type PageSeoDetails = {
  title?: string;
  excerpt?: string;
  locale?: string;
  updatedAt?: string;
  publishedAt?: string;
  pageName?: string;
  pageType?: PageType;
  category?: string;
  nextPageLink?: string;
  prevPageLink?: string;
  paginatedPage?: boolean;
  canonicalPageUrl?: string;
  structuredData?: string;
};

export type SeoManagerProps = {
  generalSettings?: SettingsSectionsSiteSettingsComponent;
  pageSeo?: SharedSeoComponent;
  pageDetails?: PageSeoDetails;
  generalInfo: {
    path: string;
  };
};
