import Link from "next/link";
import { FC } from "react";
import { cn, tw } from "utils/tailwind";
import { DropdownLinkProps } from "./DropdownLink.types";

const linkStyles = tw`pb-0.5
  text-sm
  text-grey-scale-text
  outline-none
  focus:text-green-accent-two
  active:text-green-accent-one`;

export const DropdownLink: FC<DropdownLinkProps> = ({
  active,
  href,
  onClick,
  children,
}) => (
  <div className="leading-4 2xl:leading-5">
    <Link
      href={href}
      onClick={onClick}
      className={cn(
        linkStyles,
        active
          ? "link text-green-accent-two underline underline-offset-4"
          : "link-animated"
      )}
    >
      {children}
    </Link>
  </div>
);
