import "./globals.css";
// 👆 global styles have to go first
import { PreviewBanner } from "@organisms/preview-banner";
import { PageProps } from "components/pages/types";
import { AppProps } from "next/app";
import { Router } from "next/router";
import Script from "next/script";
import { mapLocale } from "utils/seo-manager/seoManager.helpers";
import { getImageUrl } from "utils/strapi/images";
import { env } from "@/env";
import { AppComponent } from "../components/app/app";
import { prepareApplicationJsonScript } from "../components/seo/ApplicationJson/ApplicationJson";

type Props = AppProps & {
  pageProps: PageProps;
  router: Router;
};

function MyApp(props: Props) {
  const pageDetails = props.pageProps?.pageDetails;
  const pageSeo = props.pageProps?.pageSeo;
  const generalSettings = props.pageProps?.generalSettings;
  const router = props.router;

  const scriptJson =
    pageDetails?.structuredData ??
    prepareApplicationJsonScript({
      type: pageDetails?.pageType,
      url: `${env.NEXT_PUBLIC_DOMAIN}${router.asPath}`,
      title: pageSeo?.metaTitle ? pageSeo?.metaTitle : pageDetails?.title,
      primaryImage: getImageUrl(pageSeo?.metaImage?.data),
      datePublished: pageDetails?.publishedAt,
      dateModified: pageDetails?.updatedAt,
      description: pageDetails?.excerpt
        ? pageDetails?.excerpt
        : pageSeo?.metaDescription,
      locale: mapLocale(pageDetails?.locale),
      primaryImageWidth: pageSeo?.metaImage?.data?.attributes?.width,
      primaryImageHeight: pageSeo?.metaImage?.data?.attributes?.height,
      authors: pageDetails?.authors,
      keywords: pageSeo?.keywords,
      category: pageDetails?.category,
      domain: env.NEXT_PUBLIC_DOMAIN,
      path: router.asPath,
      logo: generalSettings?.favicon?.data
        ? getImageUrl(generalSettings?.favicon?.data)
        : undefined,
      logoWidth: generalSettings?.favicon?.data?.attributes?.width,
      logoHeight: generalSettings?.favicon?.data?.attributes?.height,
      pageName: pageDetails?.pageName,
    });

  return (
    <>
      <PreviewBanner previewMode={props.pageProps.previewMode} />
      <AppComponent {...props} />
      {/* https://nextjs.org/docs/messages/no-before-interactive-script-outside-document */}
      {/* eslint-disable-next-line */}
      <Script
        strategy="beforeInteractive"
        type="application/ld+json"
        id="application-json-ld"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(scriptJson) }}
      />
    </>
  );
}

export default MyApp;
