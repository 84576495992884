/**
 * @example
 * const urlWithQuery = "https://example.com/some/path/to/resource?param=value";
 * const urlWithoutQuery = "/another/path/to/resource";
 *
 * console.log(extractLastPath(urlWithQuery)); // Output: resource
 * console.log(extractLastPath(urlWithoutQuery)); // Output: resource
 */
export const extractLastSegment = (url?: string) => {
  if (!url) return undefined;
  const withoutQuery = url.split("?")[0];
  const segments = withoutQuery.split("/").filter((segment) => segment !== "");
  return segments.length ? segments[segments.length - 1] : undefined;
};

export const withoutTrailingSlash = (str: string) =>
  str.endsWith("/") ? str.slice(0, -1) : str;
