import { FC } from "react";
import { cn } from "utils/tailwind";
import { TabletNabProps } from "./TabletNav.types";

export const TabletNav: FC<TabletNabProps> = ({
  isOpen,
  children,
  className,
}) => {
  const navOpenStyles = isOpen ? "translate-x-0" : "translate-x-full";
  return (
    <div
      className={cn(
        "fixed right-0 top-0 z-40 flex h-dvh w-full min-w-0 flex-col justify-between overflow-y-scroll bg-content-primary-on-dark pb-7 transition-transform duration-500 ease-in-out md:w-1/2 md:min-w-[400px] lg:hidden",
        navOpenStyles,
        className
      )}
    >
      {children}
    </div>
  );
};
