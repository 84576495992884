import { cn, tw } from "@utils/tailwind";
import { cva } from "class-variance-authority";
import { LinkVariants } from "./Link.constants";

const defaultVariant = LinkVariants.FOOTER;
const defaultActive = true;

const baseMenuVariantLinkStyles = tw`px-[15px] py-2.5
  font-sans text-lg
  font-semibold uppercase not-italic
  leading-[22px] sm:px-[50px] sm:py-[15px] sm:text-2xl
  sm:leading-[29px]
  lg:p-0
  lg:text-[13px] lg:leading-4
  2xl:text-sm 2xl:leading-[17px]`;

export const linkStyles = cva(
  "group/link flex w-max cursor-pointer items-center font-medium not-italic leading-5 no-underline outline-none",
  {
    variants: {
      variant: {
        [LinkVariants.FOOTER]: tw`text-xs font-medium leading-5 text-blue-light
          focus:text-green-accent-one active:text-blue-saturated lg:text-sm 2xl:text-lg`,
        [LinkVariants.MENU]: cn(
          baseMenuVariantLinkStyles,
          `border-solid text-grey-scale-text

          hover:bg-green-accent-one hover:text-green-black-equivalent
          focus:bg-green-accent-one focus:text-grey-scale-text
          active:bg-green-accent-one active:text-grey-scale-text
          
          lg:hover:border-b lg:hover:border-b-green-accent-one
          lg:hover:bg-transparent lg:hover:text-grey-scale-off-black
          
          lg:focus:border-b lg:focus:border-b-green-accent-two
          lg:focus:bg-transparent lg:focus:text-green-accent-two
          
          lg:active:border-b lg:active:border-b-green-accent-two
          lg:active:bg-transparent lg:active:text-green-accent-two`
        ),
        [LinkVariants.MENU_WITH_ICON]: cn(
          baseMenuVariantLinkStyles,
          `relative
          
          border-b border-solid border-b-transparent text-grey-scale-text
          
          after:absolute after:top-[82px] after:block after:w-full
          after:scale-x-0 after:border-b
          after:border-solid after:border-b-green-accent-one
          after:transition-transform after:duration-200
          after:content-[""]
          
          hover:text-grey-scale-off-black
          focus:text-green-accent-two
          active:text-green-accent-one
          
          max-lg:hover:border-b max-lg:hover:border-solid
          max-lg:hover:border-b-transparent max-lg:hover:bg-green-accent-one
          
          max-lg:focus:border-b max-lg:focus:border-solid
          max-lg:focus:border-b-transparent
          max-lg:focus:bg-green-accent-one max-lg:focus:text-grey-scale-text
          
          max-lg:active:border-b max-lg:active:border-solid
          max-lg:active:border-b-transparent
          max-lg:active:bg-green-accent-one max-lg:active:text-grey-scale-off-black
          
          hover:after:lg:scale-x-100 focus:after:lg:scale-x-100 active:after:lg:scale-x-100
          
          max-lg:[&_svg]:size-3.5`
        ),
        [LinkVariants.MENU_LIGHT]: cn(
          baseMenuVariantLinkStyles,
          `border-solid text-blue-light

          hover:bg-green-accent-one hover:text-blue-light
          focus:bg-green-accent-one focus:text-blue-light
          active:bg-green-accent-one active:text-blue-light
          
          lg:hover:border-b lg:hover:border-b-green-accent-one
          lg:hover:bg-transparent lg:hover:text-blue-light
          
          lg:focus:border-b lg:focus:border-b-green-accent-two
          lg:focus:bg-transparent lg:focus:text-green-accent-two
          
          lg:active:border-b lg:active:border-b-green-accent-two
          lg:active:bg-transparent lg:active:text-blue-light`
        ),
        [LinkVariants.MENU_LIGHT_WITH_ICON]: cn(
          baseMenuVariantLinkStyles,
          `relative
          border-b border-solid border-b-transparent text-blue-light after:absolute
          
          after:top-[82px] after:block after:w-full after:scale-x-0 after:border-b
          after:border-solid after:border-b-green-accent-one after:transition-transform
          after:duration-200
          after:content-[""]
          
          hover:text-blue-light
          focus:text-green-accent-two
          active:text-green-accent-one
          
          max-lg:hover:border-b max-lg:hover:border-solid
          max-lg:hover:border-b-transparent
          max-lg:hover:bg-green-accent-one max-lg:hover:text-grey-scale-off-black
          
          max-lg:focus:border-b max-lg:focus:border-solid
          max-lg:focus:border-b-transparent
          max-lg:focus:bg-green-accent-one max-lg:focus:text-grey-scale-off-black
          
          max-lg:active:border-b max-lg:active:border-solid
          max-lg:active:border-b-transparent
          max-lg:active:bg-green-accent-one max-lg:active:text-grey-scale-off-black
          
          hover:after:lg:scale-x-100 focus:after:lg:scale-x-100 active:after:lg:scale-x-100
          
          max-lg:[&_svg]:size-3.5`
        ),
        [LinkVariants.SUBMENU]: tw`relative border-b
          border-solid border-b-transparent
          text-[15px] font-normal not-italic leading-[22px]
          text-grey-scale-text

          before:absolute before:-bottom-0.5
          before:left-0 before:hidden
          before:h-px before:w-full
          before:bg-green-accent-two
          before:duration-300 before:content-[""]

          after:absolute

          after:-bottom-0.5 after:left-0
          after:block after:h-px
          after:w-0 after:bg-green-accent-two
          after:duration-300
          after:content-[""] hover:after:w-full
          
          focus:text-green-accent-two
          focus:before:block
          focus:before:w-[calc(100%-10px)]
          focus:after:w-[calc(100%-10px)]
          focus:hover:before:block
          focus:hover:before:w-[calc(100%-10px)]
          focus:hover:after:w-[calc(100%-10px)]
          
          active:before:block active:before:bg-grey-scale-text

          max-2xl:text-[13px] max-2xl:font-normal max-2xl:leading-4
          max-lg:text-base max-lg:leading-5

          max-lg:hover:border-b max-lg:hover:border-solid
          max-lg:hover:border-b-transparent max-lg:hover:text-green-accent-two

          max-lg:focus:border-b max-lg:focus:border-solid
          max-lg:focus:border-b-transparent max-lg:focus:text-green-accent-two

          max-lg:active:border-b max-lg:active:border-solid
          max-lg:active:border-b-transparent

          max-sm:text-sm max-sm:leading-[18px]`,
      },
      active: {
        true: "",
        false: "",
      },
    },
    compoundVariants: [
      {
        variant: LinkVariants.SUBMENU,
        active: true,
        className: tw`text-green-accent-two 
          before:block before:w-[calc(100%-10px)]
          after:w-[calc(100%-10px)]
          hover:before:block
          hover:before:w-[calc(100%-10px)] hover:after:w-[calc(100%-10px)]`,
      },
    ],
    defaultVariants: { variant: defaultVariant, active: defaultActive },
  }
);

const baseMenuVariantIconContainerStyles = tw`ml-[7px] mr-0
  max-lg:ml-5
  max-lg:group-hover/link:visible
  max-lg:group-hover/link:mr-0
  max-lg:group-focus/link:visible
  max-lg:group-focus/link:mr-0
  max-lg:group-active/link:visible
  max-lg:group-active/link:mr-0
  max-sm:ml-3 max-sm:size-2
  [&_svg]:size-2.5
  max-lg:[&_svg]:min-h-[14px] max-lg:[&_svg]:min-w-[14px]`;

export const iconContainerStyles = cva(
  "mr-1.5 flex h-full max-h-[7px] min-h-[7px] min-w-[7px] max-w-[7px] items-center [&_svg]:size-2",
  {
    variants: {
      variant: {
        [LinkVariants.FOOTER]: "invisible",
        [LinkVariants.MENU]: cn(
          baseMenuVariantIconContainerStyles,
          `visible fill-green-accent-two
          max-lg:group-hover/link:inline-block
          max-lg:group-hover/link:fill-grey-scale-off-black
          
          max-lg:group-focus/link:inline-block
          max-lg:group-focus/link:fill-grey-scale-off-black
          
          max-lg:group-active/link:inline-block`
        ),
        [LinkVariants.MENU_WITH_ICON]: cn(
          baseMenuVariantIconContainerStyles,
          `visible max-w-[14px] fill-green-accent-one
          
          group-hover/link:fill-green-accent-one
          group-focus/link:fill-green-accent-two
          group-active/link:fill-green-accent-two
          
          max-lg:group-hover/link:fill-grey-scale-off-black
          max-lg:group-focus/link:fill-grey-scale-text
          max-lg:group-active/link:fill-grey-scale-off-black`
        ),
        [LinkVariants.MENU_LIGHT]: cn(
          baseMenuVariantIconContainerStyles,
          `visible fill-green-accent-two
          max-lg:group-hover/link:inline-block max-lg:group-hover/link:fill-blue-light
          max-lg:group-focus/link:inline-block
          max-lg:group-focus/link:fill-blue-light max-lg:group-active/link:inline-block`
        ),
        [LinkVariants.MENU_LIGHT_WITH_ICON]: cn(
          baseMenuVariantIconContainerStyles,
          `visible max-w-[14px] fill-blue-light/70
          
          group-hover/link:fill-green-accent-one group-hover/link:opacity-100
          group-focus/link:fill-green-accent-two group-focus/link:opacity-100
          group-active/link:fill-green-accent-two group-active/link:opacity-100
          
          max-lg:group-hover/link:fill-grey-scale-off-black
          max-lg:group-focus/link:fill-grey-scale-text
          max-lg:group-active/link:fill-grey-scale-off-black`
        ),
        [LinkVariants.SUBMENU]: tw`invisible
          ml-[5px] mr-0
          
          group-hover/link:visible
          group-hover/link:fill-green-accent-two
          
          group-active/link:visible
          group-active/link:fill-green-accent-two
          
          [&_svg]:opacity-100
                    
          max-lg:group-hover/link:[&_svg]:opacity-0
          max-lg:group-active/link:[&_svg]:opacity-0`,
      },
      active: { true: "", false: "" },
    },
    compoundVariants: [
      {
        variant: LinkVariants.SUBMENU,
        active: true,
        className: tw`[&_svg]:opacity-0`,
      },
    ],
    defaultVariants: { variant: defaultVariant, active: defaultActive },
  }
);

export const footerChildrenContainerStyles = tw`border-solid
  group-hover/link:border-b group-hover/link:border-b-blue-light
  group-focus/link:border-b group-focus/link:border-b-green-accent-one
  group-active/:border-b group-active/link:border-b-blue-saturated`;
