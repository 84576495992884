export type NavSectionElement = {
  label: string;
  link: string;
};

export type NavSubElement = {
  sectionTitle: string;
  children: NavSectionElement[];
};

export type NavElement = {
  id: string;
  title: string;
  children: NavSubElement[];
  link?: string;
  type?: string;
};

export const hiddenNavbarPages = ["/contact"];
