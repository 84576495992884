import { FC } from "react";
import { LinkVariants } from "../Link.constants";

type LinkIconProps = {
  linkVariant: LinkVariants;
};

export const LinkIcon: FC<LinkIconProps> = ({ linkVariant }) => {
  const generateIcon = () => {
    switch (linkVariant) {
      case LinkVariants.FOOTER:
      case LinkVariants.MENU:
      case LinkVariants.MENU_LIGHT:
        return (
          <span className="md:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 15">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.86699 7.5L0 1.58052L1.5665 0L9 7.5L1.5665 15L0 13.4195L5.86699 7.5Z"
              />
              <defs>
                <clipPath id="clip0_2650_37846">
                  <rect width="9" height="15" />
                </clipPath>
              </defs>
            </svg>
          </span>
        );
      case LinkVariants.MENU_WITH_ICON:
      case LinkVariants.MENU_LIGHT_WITH_ICON:
        return (
          <>
            <svg
              className="group-hover:hidden group-focus:hidden group-active:hidden"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 11"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.625 4.875H8.75V6.125H5.625V9.25H4.375V6.125H1.25V4.875H4.375V1.75H5.625V4.875Z"
              />
            </svg>
            <svg
              className="hidden group-hover:block group-focus:block group-active:block md:hidden"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 11"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.30811 3.18311L9.19199 4.06699L5.00005 8.25893L0.808105 4.06699L1.69199 3.18311L5.00005 6.49116L8.30811 3.18311Z"
              />
            </svg>
          </>
        );
      case "outside" as LinkVariants:
        return (
          <>
            <svg
              className="mb-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l82.7 0L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3l0 82.7c0 17.7 14.3 32 32 32s32-14.3 32-32l0-160c0-17.7-14.3-32-32-32L320 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z" />
            </svg>
          </>
        );
    }
  };

  return <>{generateIcon()}</>;
};
