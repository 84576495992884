import { Image } from "@atoms/image/Image";
import { ApiImage } from "components/pages/types";
import Link from "next/link";
import { FC } from "react";
import { getContentTypeUrl } from "utils/get-content-type-url/getContentTypeUrl";
import { ImageWithTextProps } from "./ImageWithText.types";

export const ImageWithText: FC<ImageWithTextProps> = ({ navCta }) => {
  const featureImage: ApiImage | undefined = navCta.related.featureImage
    ? {
        id: navCta.related.featureImage.id,
        attributes: navCta.related.featureImage,
      }
    : undefined;

  return (
    <Link
      href={getContentTypeUrl(navCta.related)}
      className="group/link ml-auto hidden w-1/3 cursor-pointer flex-row items-center space-x-2 border-l border-grey-scale-light-gray bg-grey-scale-light-gray/20 p-8 no-underline md:flex 2xl:space-x-5 2xl:px-20 2xl:py-10"
    >
      {featureImage && (
        <div className="relative size-32 flex-none overflow-hidden rounded-lg transition-transform duration-200 ease-in-out 2xl:size-36">
          <Image
            alt={featureImage?.attributes?.alternativeText || ""}
            maxKnownWidth={400}
            width={180}
            height={180}
            imageData={featureImage}
            backgroundImage
            className="transition-all duration-200 ease-in-out group-hover/link:scale-110 group-focus/link:rounded-xl group-focus/link:border-2 group-focus/link:border-green-accent-one"
          />
        </div>
      )}
      <div className="flex flex-col gap-2 2xl:gap-2.5">
        <div className="text-xs font-normal leading-4 tracking-tighter text-grey-scale-gray group-hover/link:text-green-mid-tier group-focus/link:text-green-dark 2xl:text-sm">
          {navCta.related.category.title}
        </div>
        <div className="text-sm font-medium leading-4 text-grey-scale-text group-hover/link:text-grey-scale-off-black group-focus/link:text-green-dark 2xl:text-base 2xl:leading-5 group-hover/link:2xl:underline group-focus/link:2xl:underline">
          {navCta.title}
        </div>
      </div>
    </Link>
  );
};
