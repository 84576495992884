import { cn, tw } from "@utils/tailwind";
import { FC } from "react";
import styles from "./ToggleMenuButton.module.css";
import { ToggleMenuButtonProps } from "./ToggleMenuButton.types";

export const ToggleMenuButton: FC<ToggleMenuButtonProps> = ({
  onClick,
  isClicked,
  className,
  isForLightBackground,
}) => {
  const barStyles = tw`min-h-[2px] rounded-full transition-all duration-100 ease-in-out`;
  const barColor = isForLightBackground
    ? tw`bg-black`
    : tw`bg-content-primary-on-dark`;
  const clickedBarStyles = isClicked ? tw`absolute mx-0 min-h-px bg-black` : "";
  const defaultBarStyles = [barStyles, barColor, clickedBarStyles];

  return (
    <div
      className={cn(
        styles.toggleMenuButton,
        className,
        isForLightBackground
          ? styles.lightToggleMenuButton
          : styles.darkToggleMenuButton,
        isClicked && styles.clickedToggleMenuButton
      )}
      onClick={onClick}
    >
      <span
        className={cn(
          "absolute right-0  opacity-0 delay-300",
          "font-sans text-[12px] font-semibold uppercase leading-[15px] tracking-[0.02em]"
        )}
      >
        CLOSE
      </span>
      <div className={cn("ml-[7px] w-[18px]", defaultBarStyles)} />
      <div className={cn("w-[25px]", defaultBarStyles)} />
      <div className={cn("mr-[7px] w-[18px]", defaultBarStyles)} />
    </div>
  );
};
