import Icon from "@atoms/icon/Icon";
import { Slottable } from "@radix-ui/react-slot";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import React from "react";
import { Loader } from "../loader/Loader";
import { buttonVariants, iconVariants } from "./Button.helpers";
import { ButtonProps } from "./Button.types";

export const Button: React.FC<ButtonProps> = ({
  className,
  intent,
  size = "medium",
  background = "light",
  icon,
  iconClassName,
  iconShowOnMobile = false,
  buttonLabel,
  children,
  asLink,
  href,
  target,
  loading,
  loaderColor,
  loaderSize,
  disabled,
  mobileFullWidth = false,
  ...props
}) => {
  const Comp = asLink ? Link : "button";

  const buttonConfig =
    intent === "tertiary" // this IF will be removed when we switch old btn variants to new
      ? "tertiary"
      : buttonLabel || children
        ? icon
          ? "text-icon"
          : "text"
        : icon
          ? "icon"
          : undefined;

  return (
    <Comp
      className={cn(
        [
          "group/button",
          "inline-block shrink-0 border border-solid font-sans no-underline",
          "focus:border-green-accent-one focus:outline-none",
          "disabled:pointer-events-none disabled:opacity-40",
          "text-center",
        ],
        buttonVariants({ background, intent, size }),
        loading && "pointer-events-none opacity-40",
        disabled
          ? "pointer-events-none cursor-default opacity-40"
          : "cursor-pointer",
        mobileFullWidth &&
          "flex w-full justify-center text-center md:inline-flex md:w-auto md:justify-normal",
        className
      )}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      href={asLink && href ? href : (undefined as any)}
      target={asLink && target ? target : undefined}
      rel={asLink ? "noopener noreferrer" : undefined}
      disabled={loading || disabled}
      {...props}
    >
      <div
        className={cn(
          "relative inline-block",
          buttonConfig === "text-icon" &&
            !loading &&
            `${iconShowOnMobile ? "animate-spring-container-backward group-hover/button:animate-spring-container-forward" : "md:animate-spring-container-backward md:group-hover/button:animate-spring-container-forward"}`,
          buttonConfig === "icon" && "size-2",
          loading && "pr-6",
          buttonConfig === "tertiary" && "h-3"
        )}
      >
        {(children || buttonLabel) && (
          <>
            <span
              className={cn(
                `whitespace-nowrap`,
                buttonConfig !== "text-icon" && "hidden",
                buttonConfig === "text-icon" && "invisible",
                loading && "hidden"
              )}
            >
              {children && <Slottable>{children}</Slottable>}
              {!children && buttonLabel && buttonLabel}
            </span>
            <span
              className={cn(
                "inline-block whitespace-nowrap",
                buttonConfig === "text-icon" &&
                  !loading &&
                  "absolute top-0 animate-spring-text-backward group-hover/button:animate-spring-text-forward",
                size === "small" && "p-px",
                intent === "tertiaryV2" &&
                  buttonConfig === "text" &&
                  "group-hover/button:underline"
              )}
            >
              {children && <Slottable>{children}</Slottable>}
              {!children && buttonLabel && buttonLabel}
            </span>
          </>
        )}

        {icon && !loading && (
          <span
            className={cn(
              (children || buttonLabel) &&
                !iconShowOnMobile &&
                `hidden md:block`,
              buttonConfig !== "tertiary" && "absolute top-1/2 size-4",
              buttonConfig === "text-icon" &&
                "animate-spring-icon-backward group-hover/button:animate-spring-icon-forward",
              buttonConfig === "icon" &&
                "right-1/2 -translate-y-1/2 translate-x-1/2"
            )}
          >
            <Icon
              name={icon}
              className={cn(iconVariants({ size, className: iconClassName }))}
            />
          </span>
        )}

        {loading && (
          <span className="absolute right-0 top-1/2 -translate-y-1/2">
            <Loader size={loaderSize === "lg" ? 4 : 2} color={loaderColor} />
          </span>
        )}
      </div>
    </Comp>
  );
};
