import { SmallComponentsBlogAuthorComponent } from "@api/contract";
import md5 from "md5";
import { PageType } from "utils/seo-manager/seoManager.constants";
import { getImageUrl } from "utils/strapi/images";

export type ApplicationJsonScriptProps = {
  type?: PageType;
  url: string;
  title?: string;
  primaryImage?: string;
  datePublished?: string;
  dateModified?: string;
  description?: string;
  locale?: string;
  primaryImageWidth?: number;
  primaryImageHeight?: number;
  authors?: SmallComponentsBlogAuthorComponent[];
  keywords?: string[];
  category?: string;
  domain?: string;
  path: string;
  logo?: string;
  logoHeight?: number;
  logoWidth?: number;
  pageName?: string;
};

export const prepareApplicationJsonScript = ({
  type,
  url,
  title,
  primaryImage,
  datePublished,
  dateModified,
  description,
  locale,
  primaryImageWidth,
  primaryImageHeight,
  authors,
  keywords,
  category,
  domain,
  path,
  logo,
  logoHeight,
  logoWidth,
  pageName,
}: ApplicationJsonScriptProps) => {
  const createBreadcrumbsStructure = (path: string) => {
    const pathArray = path.replace(/\/$/, "").split("/");
    const breadcrumbs = pathArray.map((_path, index) => {
      const pathTo = pathArray.slice(0, index + 1).join("/");
      const isLast = index === pathArray.length - 1;
      const isFirst = index === 0;

      const generateName = () => {
        if (isFirst) {
          return "Home";
        }

        if (isLast) {
          return title;
        }

        if (index === 1) {
          return pageName;
        }

        if (index === 2 && category) {
          return category;
        }
      };

      const name = generateName();

      if (name) {
        return {
          "@type": "ListItem",
          position: index + 1,
          item: `${domain}${pathTo}`,
          name: name,
        };
      }
    });

    const filteredBreadcrumbs = breadcrumbs.filter(Boolean);

    return filteredBreadcrumbs.map((breadcrumb, index) => ({
      ...breadcrumb,
      position: index + 1,
    }));
  };

  const generateAuthors = () => {
    if (!authors) {
      return [];
    }

    return authors.map((el) => ({
      "@type": "Person",
      ...(el.author
        ? {
            name: el.author,
            "@id": `https://virtuslab.com/#/schema/person/${md5(el.author)}`,
          }
        : {}),
      image: {
        "@type": "ImageObject",
        inLanguage: locale,
        "@id": "https://virtuslab.com/#/schema/person/image/",
        url: getImageUrl(el.authorFace?.data),
        contentUrl: getImageUrl(el.authorFace?.data),
        caption: el.author,
      },
    }));
  };

  const generateArticleAuthors = () => {
    if (!authors) {
      return [];
    }
    return authors?.map((el) =>
      el.author
        ? {
            name: el.author,
            "@id": `https://virtuslab.com/#/schema/person/${md5(el.author)}`,
          }
        : {}
    );
  };

  // noinspection HttpUrlsUsage
  const jsonData = {
    "@context": "https://schema.org",
    "@graph": [
      ...(type === PageType.WEB_PAGE
        ? [
            {
              "@type": "WebPage",
              "@id": url,
              url: url,
              name: title,
              isPartOf: {
                "@id": "https://virtuslab.com/#website",
              },
              about: {
                "@id": "https://virtuslab.com/#organization",
              },
              primaryImageOfPage: {
                "@id": primaryImage,
              },
              image: {
                "@id": primaryImage,
              },
              thumbnailUrl: primaryImage,
              datePublished,
              dateModified,
              description,
              breadcrumb: {
                "@id": "https://virtuslab.com/#breadcrumb",
              },
              inLanguage: locale,
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: [url],
                },
              ],
            },
          ]
        : []),

      ...(type === PageType.ARTICLE
        ? [
            {
              "@type": "Article",
              "@id": `${url}#article`,
              isPartOf: {
                "@id": url,
              },
              headline: title,
              datePublished,
              dateModified,
              mainEntityOfPage: {
                "@id": url,
              },
              publisher: {
                "@id": "https://virtuslab.com/#organization",
              },
              image: {
                "@id": primaryImage,
              },
              thumbnailUrl: primaryImage,
              keywords,
              articleSection: category ? [category] : category,
              inLanguage: locale,
              author: [...generateArticleAuthors()],
            },
            ...generateAuthors(),
          ]
        : []),

      ...(type === PageType.CONTACT
        ? [
            {
              "@context": "http://schema.org",
              "@type": "ContactPage",
              name: "Contact VirtusLab",
              isPartOf: "https://virtuslab.com/",
              headline: title,
              description: "Discover how we can assist.",
              datePublished,
              dateModified,
              publisher: {
                "@id": "https://virtuslab.com/#organization",
              },
            },
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              URL: "https://virtuslab.com/",
            },
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              name: "VirtusLab",
              mainEntityOfPage: "https://virtuslab.com/",
              url: "https://virtuslab.com/",
              slogan:
                "Software engineering to build and sustain a competitive edge",
              sameAs: [
                "https://www.facebook.com/virtuslab",
                "https://www.linkedin.com/company/virtuslab/mycompany/",
                "https://twitter.com/VirtusLab",
              ],
              email: "info@virtuslab.com",
              contactPoint: {
                "@type": "ContactPoint",
                contactType: "ContactPage",
                url: "https://virtuslab.com/contact",
              },
              legalName: "VirtusLab z o.o.",
              foundingDate: "2012",
              member: {
                "@type": "Person",
                name: "Rafał Pokrywka",
                jobTitle: "CEO",
                nationality: "Poland",
                url: "https://www.linkedin.com/in/rpokrywka/",
              },
              address: {
                "@type": "PostalAddress",
                addressLocality: "Cracow",
                postalCode: "31-153",
                streetAddress: "Szlak 49",
                addressCountry: "PL",
                addressRegion: "Lesser Poland Voivodeship",
              },
              logo,
              numberOfEmployees: "500",
              vatID: "PL5170312965",
            },
          ]
        : []),

      {
        "@type": "ImageObject",
        inLanguage: locale,
        "@id": primaryImage,
        url: primaryImage,
        contentUrl: primaryImage,
        width: primaryImageWidth,
        height: primaryImageHeight,
      },

      {
        "@type": "BreadcrumbList",
        "@id": "https://virtuslab.com/#breadcrumb",
        itemListElement: createBreadcrumbsStructure(path),
      },

      {
        "@type": "WebSite",
        "@id": "https://virtuslab.com/#website",
        url: "https://virtuslab.com/",
        name: "VirtusLab",
        description: "",
        publisher: {
          "@id": "https://virtuslab.com/#organization",
        },
        // potentialAction: [
        //   {
        //     "@type": "SearchAction",
        //     target: {
        //       "@type": "EntryPoint",
        //       urlTemplate: "https://virtuslab.com/?s={search_term_string}",
        //     },
        //     "query-input": "required name=search_term_string",
        //   },
        // ],
        inLanguage: locale,
      },

      {
        "@type": "Organization",
        "@id": "https://virtuslab.com/#organization",
        name: "VirtusLab sp. z o.o.",
        url: "https://virtuslab.com/",
        logo: {
          "@type": "ImageObject",
          inLanguage: locale,
          "@id": "https://virtuslab.com/#/schema/logo/image/",
          url: logo,
          contentUrl: logo,
          width: logoWidth,
          height: logoHeight,
          caption: "VirtusLab sp. z o.o.",
        },
        image: {
          "@id": "https://virtuslab.com/#/schema/logo/image/",
        },
        sameAs: [
          "https://www.facebook.com/virtuslab",
          "https://twitter.com/VirtusLab",
          "https://www.linkedin.com/company/virtuslab/",
        ],
      },

      {
        "@context": "http://schema.org",
        "@type": "Organization",
        name: "VirtusLab",
        mainEntityOfPage: "https://virtuslab.com/",
        url: "https://virtuslab.com/",
        slogan: "Software engineering to build and sustain a competitive edge",
        sameAs: [
          "https://www.facebook.com/virtuslab",
          "https://www.linkedin.com/company/virtuslab/mycompany/",
          "https://twitter.com/VirtusLab",
        ],
        email: "info@virtuslab.com",
        contactPoint: {
          "@type": "ContactPoint",
          contactType: "ContactPage",
          url: "https://virtuslab.com/contact",
        },
        legalName: "VirtusLab z o.o.",
        foundingDate: "2012",
        member: {
          "@type": "Person",
          name: "Rafał Pokrywka",
          jobTitle: "CEO",
          nationality: "Poland",
          url: "https://www.linkedin.com/in/rpokrywka/",
        },
        address: {
          "@type": "PostalAddress",
          addressLocality: "Cracow",
          postalCode: "31-153",
          streetAddress: "Szlak 49",
          addressCountry: "PL",
          addressRegion: "Lesser Poland Voivodeship",
        },
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "4.4",
          reviewCount: "8",
          url: "https://clutch.co/profile/virtuslab",
        },
        logo: "https://virtuslab.com/wp-content/uploads/2019/11/logo_virtus.png",
        numberOfEmployees: "500",
        vatID: "PL5170312965",
      },
    ],
  };

  return {
    ...jsonData,
    "@graph": jsonData["@graph"].map((dataObj: { [key: string]: unknown }) =>
      Object.entries(dataObj)
        .filter(([_key, value]) => value !== undefined && value !== null)
        .reduce(
          (obj, [key, value]) => {
            obj[key] = value;
            return obj;
          },
          {} as { [key: string]: unknown }
        )
    ),
  };
};
